<div
  class="bg-[#FFFFFF] break-words hyphens-auto mt-5 overflow-x-hidden"
>
  <section
    #firstSection
    class="min-h-[78vh] mt-10 relative home-bg"
  >
    <div
      *ngIf="alertPopUp"
      class="fixed top-0 z-50 inset-0 overflow-y-atuo"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <form
          [formGroup]="zipAreaForm"
          (submit)="submitEmail()"
          class="fixed top-14 modal inline-block z-50 bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:my-0 sm:align-middle sm:max-w-lg sm:w-full w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="flex justify-between border-b-2">
              <h3 class="text-lg font-medium rounded-md p-4" id="modal-title">
                Ihre Region wird momentan noch nicht bedient
              </h3>

              <button (click)="closePopUp()" class="text-lg font-medium">
                <img class="p-4" src="../../assets/icons/modalX.svg" alt="x" />
              </button>
            </div>
            <div class="pt-5">
              <p class="px-2 inline-block mb-4">
                <img
                  class="inline-block"
                  src="../../assets/icons/warning.svg"
                  alt="warning"
                />
                Bitte stellen Sie sicher, dass Sie die Postleitzahl ohne die Gemeinde eingeben, sollte diese Meldung erneut kommen geben Sie bitte Ihre E-Mail im untenstehenden Feld an. Wir werden Sie kontaktieren, sobald wir Ihre Region bedienen.
              </p>
            </div>
            <div class="mb-4 relative">
              <input
                id="email"
                type="email"
                class="shadow appearance-none border rounded w-[70%] py-[14px] px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                formControlName="email"
                placeholder="Email"
              />

              <span
                *ngIf="
                  zipAreaForm.get('email')?.hasError('email') ||
                  (zipAreaForm.get('email')?.hasError('required') &&
                    zipAreaForm.get('email')?.touched)
                "
                class="text-red-500 absolute left-0 -bottom-5"
                >Email ist erforderlichEintragen</span
              >
              <button
                type="submit"
                class="bg-turquoise-tertiary text-white font-bold ml-3 p-[14px] w-[26%] rounded-xl rounded-br-xl"
              >
              Eintragen
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="container mx-auto flex">
      <div class="z-30 2xl:mx-24 min-h-[78vh] md:w-full rounded-tl-3xl">
        <div
          class="mx-0 w-full absolute center md:mx-5 md:mr-0 z-20 md:max-w-[50%]"
        >
          <div
            class="bg-gradient-new sm:max-w-[528px] min-h-[540px] flex flex-col gap-10 justify-between sm:rounded-tl-3xl sm:rounded-br-3xl p-5 relative"
          >
            <img class="w-[256px] hei sm:block hidden absolute z-10 top-1/2 -translate-y-1/2 -right-[78px]" src="../../assets/images/pebraquality.png" alt="pebraquality" />
            <h4 class="mx-auto text-center sm:text-left uppercase font-normal text-white">
              Buchen Sie jetzt Ihre Putzkraft in Zürich.
            </h4>
            <ul class="text-white ul">
              <li class="text-[18px]">Expressreinigungen möglich</li>
              <li class="text-[18px]">Ausgebildetes Reinigungspersonal</li>
              <li class="text-[18px]">Individuelle Lösungen</li>
              <li class="text-[18px]">Einfache Onlinebuchung</li>
            </ul>
            <form class="md:p-5 sm:block flex flex-col relative sm:w-full">
              <input
                class="sm:inline-block outline-none block sm:w-[70%] border-0 py-3 mt-5 pl-7 pr-20 bg-blue-secondary text-gray-ducktertiary placeholder:text-gray-ducktertiary sm:rounded-tl-[4px] sm:rounded-bl-[4px] rounded-[4px] sm:rounded-none"
                type="text"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="searchText"
                (click)="handleSearchText()"
                placeholder="Ihre Postleitzahl"
              />
              <button
                (click)="handleReservationButton()"
                class="bg-turquoise-duckfortiery hover:bg-violet-brightpurple w-full sm:w-[30%] p-3 sm:rounded-tr-[4px] sm:rounded-br-[4px] text-white rounded-[4px] sm:rounded-none sm:mt-0 mt-2 self-end"
              >
              Los gehts!
              </button>
              @if(searchText.length > 0 && displayDropdown) {
              <div
                class="z-50 drop my-2 rounded-2xl bg-white max-h-[200px] h-fit md:w-[60%] overflow-y-scroll absolute"
              >
                <ul class="p-2">
                  <li
                    (click)="setSearchText(zip.zip, zip.city)"
                    class="hover:bg-green-secondary rounded-lg cursor-pointer"
                    *ngFor="
                      let zip of zipCode[0]
                        | filter : searchText : ['zip', 'city', 'cityZip']
                    "
                  >
                    <p class="p-2 inline-block cursor-pointer">
                      {{ zip.zip }} {{ zip.city }}
                    </p>
                  </li>
                </ul>
              </div>
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section #section1  class="py-5 container mx-auto overflow-hidden">
    <h4 class="text-center w-full md:py-7 py-0 text-turquoise-duckfortiery font-medium uppercase !text-[32px] sm:!text-[48px]">Wie es funktioniert</h4>
    <div
      [@slide]="isVisible"
      class="md:mx-auto flex flex-col justify-between"
    >
      <div
        class="flex sm:flex-row flex-wrap flex-col justify-between sm:justify-center lg:justify-between break-words hyphens-auto"
      >
        @for (services of serviceCards; track services; let index = $index) {
        <div
          class="sm:w-[49%] lg:w-[33%] p-5"
        >
          <img class="w-[100px] h-[113px] mx-auto" src={{services.icon}} alt={{services.title}}>
          <h6 class="text-center w-full text-turquoise-duckfortiery font-medium uppercase !text-[18px] sm:!text-[24px]"><p class="h-[32px] w-[32px] text-[20px] inline-block rounded-full border-[2px] border-[#61366E]">{{index + 1}}</p> {{services.title}}</h6>
          <p class="mx-auto text-center w-[265px]">{{services.service}}</p>
        </div>
        }
      </div>
      <a
        class="bg-turquoise-duckfortiery block mx-auto uppercase my-10 text-white hover:bg-violet-brightpurple font-medium text-[16px] px-[20px] py-[16px] rounded-[12px]"
        href="https://{{pebraApp}}/private"
      >
        Jetzt Reinigung buchen
      </a>
    </div>
  </section>
  <section #section2 class="bg-green-primary break-words overflow-hidden hyphens-auto py-5">
    <div
    [@slide]="isVisible2"
      class="container mx-auto md:py-10 overflow-hidden"
    >
      <h4 class="text-center w-full text-turquoise-duckfortiery font-medium uppercase !text-[32px] sm:!text-[48px]">Unsere Leistungen</h4>
      <div class="flex sm:flex-row flex-wrap flex-col justify-between md:pt-12 mt-5 break-words hyphens-auto">
        @for (card of currentCards; track card; let index = $index) {
          <a
          href="https://{{pebraApp}}/private"
          class="lg:w-[49%] cursor-pointer p-10 sm:hover:scale-105 ease-in-out transition flex flex-col gap-4"
          >
            <h6 class="w-full text-turquoise-duckfortiery font-medium uppercase !text-[18px] sm:!text-[24px]">{{card.title}}</h6>
            <img src={{card.img}} alt={{card.title}}>
            <p>{{card.desc}}</p>
          </a>
        }
      </div>
      <a
      class="bg-turquoise-duckfortiery block mx-auto max-w-[150px] text-center uppercase my-10 text-white hover:bg-violet-brightpurple font-medium text-[16px] px-[20px] py-[16px] rounded-[12px]"
      href="https://{{pebraApp}}/private"
    >
      Jetzt buchen
    </a>
    </div>
  </section>
  <section #section3 class="bg-turquoise-duckfortiery py-16 overflow-hidden">
      <div [@slide]="isVisible3" class="container mx-auto">
        <h4 class="text-center w-full text-white font-medium uppercase !text-[32px] sm:!text-[48px]">Warum Pebra wählen?</h4>
        <div class="mx-5 flex flex-wrap flex-row gap-4 my-10">
          @for (cards of pebraCards; track cards; let index = $index) {
            <div
            class="lg:w-[24%] sm:w-[48%] cursor-pointer w-full bg-white rounded-tl-[12px] rounded-br-[12px] sm:hover:scale-105 transition ease-in-out p-5"
            >
              <img class="w-[120px] h-[139px] mx-auto" src={{cards.img}} alt={{cards.title}}>
              <h6 class="w-full mx-auto text-center text-turquoise-duckfortiery font-medium uppercase !text-[18px] sm:!text-[24px]">{{cards.title}}</h6>
              <p class="text-center">{{cards.desc}}</p>
            </div>
          }
        </div>
      </div>
  </section>
  <section #section4 class="pt-10 overflow-hidden">
    <div [@slide]="isVisible4" class="py-10 md:container mx-auto">
      <h4 class="text-center w-full text-turquoise-duckfortiery font-medium uppercase mb-10 !text-[32px] sm:!text-[48px]">Das sagen unsere Kunden</h4>
      <div class="swiper-container md:mx-16" #swiperContainer>
        <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let card of slides;">
                <div class="slide-content mx-auto h-[405px] sm:h-[380px] w-[322px] sm:w-[350px] relative pt-5 rounded-tl-3xl bg-[#E6F3F573] shadow-lg shadow-[#00829A24] rounded-br-3xl p-5 cursor-grab">
                    <div class="flex gap-2 mt-2">
                        <ng-container *ngFor="let rating of [].constructor(card.rating)">
                            <img class="inline"
                                src="../../assets/icons/home-star-new.svg"
                                alt="star" />
                        </ng-container>
                    </div>
                    <img class="py-5" src="../../../assets/icons/quotes-end.svg" alt="quotes">
                    <p class="pt-5">“{{ card.description }}“</p>
                    <div class="absolute bottom-0 left-5">
                        <p class="text-[18px] font-medium pb-5">{{ card.name }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="md:mx-5 pt-10 flex justify-center gap-4">
        <button (click)="swiper.slidePrev()" class="h-[42px] flex items-center justify-center w-[42px] rounded-full bg-turquoise-duckfortiery hover:bg-violet-brightpurple">
            <img src="../../assets/icons/home-chevron-left-new.svg" alt="Previous" />
        </button>
        <button (click)="swiper.slideNext()" class="h-[42px] w-[42px] flex items-center justify-center rounded-full bg-turquoise-duckfortiery hover:bg-violet-brightpurple">
            <img class="" src="../../assets/icons/chevron-right-new.svg" alt="Next" />
        </button>
    </div>
    </div>
  </section>
  <section #section5 class="container mx-auto py-16 overflow-hidden">
    <div [@slide]="isVisible5" class="mx-5">
      <h4 class="text-center mx-auto w-full max-w-[714px] text-turquoise-duckfortiery font-medium uppercase !text-[32px] sm:!text-[48px]">Ihr Reinigungsservice in Zürich und Umgebung</h4>
      <p class="text-center mx-auto w-full my-5 max-w-[714px]">Pebra ist Ihr zuverlässiger Partner für professionelle Reinigung in Zürich. Egal ob im Stadtzentrum, in Seefeld, Oerlikon oder anderen Stadtteilen – unsere Putzfrau in Zürich ist schnell bei Ihnen vor Ort. Kontaktieren Sie uns und lassen Sie Ihr Zuhause in neuem Glanz erstrahlen!</p>
      <div (click)="goToMap()" class="mx-auto w-full max-w-[928px] cursor-pointer relative">
        <img src="../../assets/images/pebramap.jpg" class="mx-auto hidden transition ease-in-out sm:block sm:hover:scale-105" alt="map">
        <img src="../../assets/images/map.png" class="mx-auto sm:hidden transition ease-in-out sm:hover:scale-105" alt="map">
      </div>
    </div>
  </section>
  <section #section6 class="break-words bg-turquoise-duckfortiery hyphens-auto overflow-hidden">
    <div [@slide]="isVisible6" class="py-16">
      <div class="container mx-auto">
        <h4 class="text-center mx-auto w-full text-white font-medium uppercase !text-[32px] sm:!text-[48px]">Zahlen & Fakten</h4>
        <p class="text-center mx-auto text-white w-full my-5 max-w-[992px]">Seit über 15 Jahren ist Pebra führend in der Schweizer Reinigungsbranche. Mit über 100 engagierten Mitarbeitenden bieten wir Lösungen für bereits über 10'000 zufriedene Privatkunden. Vertrauen Sie auf unsere Erfahrung und Kompetenz für erstklassige Sauberkeit und Service.</p>
        <div class="mx-5 flex flex-wrap flex-row gap-4 my-10">
          @for (cards of factsCards; track cards; let index = $index) {
            <div
            class="lg:w-[24%] cursor-pointer sm:w-[48%] w-full bg-turquoise-tertiary rounded-tl-[12px] rounded-br-[12px] hover:scale-105 ease-in-out transition p-5"
            >
              <img class="w-[120px] h-[139px] mx-auto" [src]="cards.img" alt={{cards.title}}>
              <h6 class="w-full mx-auto text-center text-white font-medium uppercase !text-[18px] sm:!text-[20px]">{{cards.title}}</h6>
            </div>
          }
        </div>
      </div> 
    </div>
  </section>
  <section #section7 class="container mx-auto py-16 overflow-hidden">
    <div [@slide]="isVisible7" class="mx-5">
      <h4 class="text-center mx-auto w-full max-w-[714px] text-turquoise-duckfortiery font-medium uppercase !text-[32px] sm:!text-[48px]">Häufig gestellte Fragen</h4>
      <div class="mt-10">
        @for(kategorie of faqData; track kategorie; let index = $index) {
          <div
            class="rounded-br-2xl md:mx-auto mb-3 rounded-tl-2xl transition ease-in-out transform duration-300 border border-turquoise-duckfortiery"
          >
            <div (click)="handleContent(index)" class="flex p-10 items-center justify-between cursor-pointer">
              <h5 class="text-turquoise-duckfortiery uppercase text-[20px] w-[70%] font-medium">
                {{kategorie.title}}
              </h5>
              <div class="sm:h-[42px] h-[24px] flex items-center justify-center sm:w-[42px] w-[24px] rounded-full bg-turquoise-duckfortiery hover:bg-violet-brightpurple">
                <img
                  class="cursor-pointer w-[20px] sm:w-full"
                  [ngClass]="{
                    'transition ease-in-out transform duration-300': true,
                    '!-rotate-180': kategorie.showContent
                  }"
                  src="../../../assets/icons/chevron-down-new.svg"
                  alt="random"
                />
              </div>
            </div>
            <div *ngIf="kategorie.showContent" class="px-10 pb-10">
              <p>
                {{kategorie.desc}}
              </p>
            </div>
          </div>
          }
      </div>
    </div>
  </section>
</div>