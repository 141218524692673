import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private scrollToServicesSubject = new Subject<void>();
  private scrollToFaqSubject = new Subject<void>();
  private scrollToCommentsSubject = new Subject<void>();

  scrollToServices$ = this.scrollToServicesSubject.asObservable();

  emitScrollToServices() {
    this.scrollToServicesSubject.next();
  }

  scrollToFaq$ = this.scrollToFaqSubject.asObservable();

  emitScrollToFaq() {
    this.scrollToFaqSubject.next();
  }

  scrollToComments$ = this.scrollToCommentsSubject.asObservable();

  emitScrollToComments() {
    this.scrollToCommentsSubject.next();
  }
}