import { Routes } from '@angular/router';

// Components
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { SuccessComponent } from './success/success.component';
import { ErrorComponent } from './error/error.component';
import { DataprotectionComponent } from './dataprotection/dataprotection.component';
import { ZurichComponent } from './zurich/zurich.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'FAQ',
    loadChildren: () =>
      import('./faq/faq-component.routes').then((m) => m.routes),
  },
  {
    path: 'zurich',
    component: ZurichComponent,
  },
  {
    path: 'success',
    component: SuccessComponent,
  },
  {
    path: 'failed',
    component: ErrorComponent,
  },
  {
    path: 'imprint',
    component: DataprotectionComponent,
  },
  {
    path: '**',
    redirectTo: 'failed',
  },
];
