<div
  class="md:mx-5 mx-2 bg-[#F5F5F7] rounded-tl-3xl rounded-br-3xl break-words hyphens-auto mt-5 overflow-x-hidden"
>
  <section
    #firstSection
    class="bg-gradient min-h-[91vh] rounded-br-3xl rounded-tl-3xl relative"
  >
    <div
      *ngIf="alertPopUp"
      class="fixed top-0 z-50 inset-0 overflow-y-atuo"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <form
          [formGroup]="zipAreaForm"
          (submit)="submitEmail()"
          class="fixed top-14 modal inline-block z-50 bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:my-0 sm:align-middle sm:max-w-lg sm:w-full w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="flex justify-between border-b-2">
              <h3 class="text-lg font-medium rounded-md p-4" id="modal-title">
                {{'home-page.zippopup.title'| translate}}
              </h3>

              <button (click)="closePopUp()" class="text-lg font-medium">
                <img class="p-4" src="../../assets/icons/modalX.svg" alt="x" />
              </button>
            </div>
            <div class="pt-5">
              <p class="px-2 inline-block mb-4">
                <img
                  class="inline-block"
                  src="../../assets/icons/warning.svg"
                  alt="warning"
                />
                {{'home-page.zippopup.desc'| translate}}
              </p>
            </div>
            <div class="mb-4 relative">
              <input
                id="email"
                type="email"
                class="shadow appearance-none border rounded w-[70%] py-[14px] px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                formControlName="email"
                placeholder="Email"
              />

              <span
                *ngIf="
                  zipAreaForm.get('email')?.hasError('email') ||
                  (zipAreaForm.get('email')?.hasError('required') &&
                    zipAreaForm.get('email')?.touched)
                "
                class="text-red-500 absolute left-0 -bottom-5"
                >{{ "home-page.modal.email-error" | translate }}</span
              >
              <button
                type="submit"
                class="bg-turquoise-tertiary text-white font-bold ml-3 p-[14px] w-[26%] rounded-xl rounded-br-xl"
              >
              {{'home-page.zippopup.register'| translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="container mx-auto flex">
      <div class="md:block">
        <img
          src="../../assets/icons/homepage-element1.svg"
          class="absolute rounded-tl-3xl top-0 left-0"
          alt="random"
        />
        <img
          src="../../assets/icons/homepage-element2.svg"
          class="absolute rounded-br-3xl bottom-0 right-0"
          alt="random"
        />
        <img
          src="../../assets/icons/homepage-element-3.svg"
          class="absolute bottom-0 rounded-br-3xl right-0"
          alt="random"
        />
      </div>
      <div class="z-30 2xl:mx-24 min-h-[91vh] md:w-full rounded-tl-3xl">
        <div
          class="mx-0 absolute center md:mx-5 md:px-0 px-2 md:mr-0 z-20 md:max-w-[50%]"
        >
          <div class="mx-0 md:mx-5 break-normal hyphens-manual my-2">
            <button
              [ngClass]="{
                'opacity-50': pursue
              }"
              (click)="handlePrivateBooking()"
              class="py-3 px-5 md:px-10 btn-outline md:w-[180px] rounded-tl-xl rounded-br-xl text-gray-duckfortiery font-bold active:bg-green-tertiary"
            >
              {{ "home-page.banner.private" | translate }}
            </button>
            <button
              [ngClass]="{
                'opacity-50': private
              }"
              (click)="handlePursueBooking()"
              class="py-3 px-5 md:px-10 md:w-[180px] btn-outline rounded-tl-xl ml-2 rounded-br-xl text-gray-duckfortiery font-bold active:bg-green-tertiary"
            >
              {{ "home-page.banner.pursue" | translate }}
            </button>
          </div>
          <div
            class="mx-0 md:mx-5 bg-green-secondary sm:w-[588px] rounded-br-2xl p-5 px-2 md:px-5"
          >
            @if(private) {
            <h4 class="mb-0 font-bold">
              {{ "home-page.banner.booking" | translate }}
            </h4>
            <ul class="list-disc text-gray-800 ml-5 md:ml-10 py-2 md:py-5">
              <li class="font-bold font-sans">
                {{ "home-page.banner.express" | translate }}
              </li>
              <li>{{ "home-page.banner.professional" | translate }}</li>
              <li>{{ "home-page.banner.always" | translate }}</li>
              <li>{{ "home-page.banner.flexible" | translate }}</li>
            </ul>
            <form class="md:py-5 md:block flex flex-col relative">
              <input
                class="md:inline-block block md:w-[50%] border-0 py-3 mt-5 pl-7 pr-20 bg-blue-secondary text-gray-ducktertiary placeholder:text-gray-ducktertiary md:rounded-tl-xl md:rounded-bl-xl rounded-xl md:rounded-none"
                type="text"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="searchText"
                (click)="handleSearchText()"
                placeholder="{{ 'home-page.banner.post-number' | translate }}"
              />
              <button
                (click)="handleReservationButton()"
                class="bg-turquoise-tertiary md:w-[30%] w-[50%] p-3 md:rounded-tr-xl md:rounded-br-xl text-white rounded-xl md:rounded-none md:mt-0 mt-2 self-end"
              >
                {{ "home-page.banner.go-btn" | translate }}
              </button>
              @if(searchText.length > 0 && displayDropdown) {
              <div
                class="z-50 drop my-2 rounded-2xl bg-white max-h-[200px] h-fit md:w-[50%] overflow-y-scroll absolute"
              >
                <ul class="p-2">
                  <li
                    (click)="setSearchText(zip.zip, zip.city)"
                    class="hover:bg-green-secondary rounded-lg cursor-pointer"
                    *ngFor="
                      let zip of zipCode[0]
                        | filter : searchText : ['zip', 'city', 'cityZip']
                    "
                  >
                    <p class="p-2 inline-block cursor-pointer">
                      {{ zip.zip }} {{ zip.city }}
                    </p>
                  </li>
                </ul>
              </div>
              }
            </form>
            }
            @if(pursue) {
              <h4 class="mb-0 font-bold">
                {{ "home-page.banner.bussines" | translate }}
              </h4>
              <ul class="list-disc text-gray-800 ml-5 md:ml-10 py-2 md:py-5">
                <li class="font-bold font-sans">
                  {{ "home-page.banner.express" | translate }}
                </li>
                <li>{{ "home-page.banner.professional" | translate }}</li>
                <li>{{ "home-page.banner.always" | translate }}</li>
                <li>{{ "home-page.banner.flexible" | translate }}</li>
              </ul>
              <form class="md:py-5 md:block flex flex-col relative">
                <input
                  class="md:inline-block block md:w-[50%] border-0 py-3 mt-5 pl-7 pr-20 bg-blue-secondary text-gray-ducktertiary placeholder:text-gray-ducktertiary md:rounded-tl-xl md:rounded-bl-xl rounded-xl md:rounded-none"
                  type="text"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="searchText"
                  (click)="handleSearchText()"
                  placeholder="{{ 'home-page.banner.post-number' | translate }}"
                />
                <button
                  (click)="handleReservationButton()"
                  class="bg-turquoise-tertiary md:w-[30%] w-[50%] p-3 md:rounded-tr-xl md:rounded-br-xl text-white rounded-xl md:rounded-none md:mt-0 mt-2 self-end"
                >
                  {{ "home-page.banner.go-btn" | translate }}
                </button>
                @if(searchText.length > 0 && displayDropdown) {
                <div
                  class="z-50 drop my-2 rounded-2xl bg-white max-h-[200px] h-fit md:w-[50%] overflow-y-scroll absolute"
                >
                  <ul class="p-2">
                    <li
                      class="hover:bg-green-secondary rounded-lg"
                      *ngFor="
                        let zip of zipCode[0]
                          | filter : searchText : ['zip', 'city', 'cityZip']
                      "
                    >
                      <p
                        (click)="setSearchText(zip.zip, zip.city)"
                        class="p-2 inline-block cursor-pointer"
                      >
                        {{ zip.zip }} {{ zip.city }}
                      </p>
                    </li>
                  </ul>
                </div>
                }
              </form>
            }
          </div>
        </div>
      </div>
      <div class="md:block hidden absolute bottom-[-90px] right-[15%]">
        <img class="w-64 md:block hidden absolute z-10 -top-4 right-[52%]" src="../../assets/images/pebraquality.png" alt="random" />
        <img class="relative z-10" src="../../assets/icons/cleaning-lady-home.svg" alt="random" />
      </div>
      <div class="md:block hidden absolute bottom-10 right-0">
        <img src="../../assets/icons/cleaning-boy-home.svg" alt="random" />
      </div>
    </div>
  </section>
  <section id="review" class="mx-5 md:mx-0 py-5 md:py-10 relative">
    <div
      class="container md:mx-auto flex flex-col md:flex-row justify-between py-5"
    >
      <div class="py-5 w-full md:w-[50%] md:mx-10">
        <p
          class="bg-violet-secondary w-fit text-violet-brightpurple rounded-md uppercase px-5 font-bold font-sans mb-5"
        >
          {{ "home-page.review-section.reviews" | translate }}
        </p>
        <h4 class="text-gray-duckfortiery font-bold">
          <!-- Kundenstimmen zu unserer Qualitätsreinigung -->
          {{ "home-page.review-section.customer-review" | translate }}
        </h4>
      </div>
      <div class="py-5 md:mx-10 md:w-[50%] md:mt-10">
        <p class="py-5">
          {{ "home-page.review-section.review-content" | translate }}
        </p>
        <button
          (click)="openModal()"
          class="bg-turquoise-tertiary text-white font-bold p-[14px] rounded-xl rounded-br-xl"
        >
          {{ "general.evaluate" | translate }}
        </button>
        <!-- <button
          class="border-turquoise-tertiary border-2 ml-5 font-bold p-3 text-turquoise-tertiary rounded-xl rounded-br-xl"
        >
          {{ "general.view-all" | translate }}
        </button> -->
      </div>
    </div>
    <!-- modal -->
    <div
      *ngIf="modalOpen"
      class="absolute z-50 inset-0 overflow-y-atuo"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >

        <form
          [formGroup]="form"
          (submit)="submitReview()"
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3
              class="text-lg font-medium bg-violet-secondary w-fit text-violet-brightpurple rounded-md uppercase px-2 mb-4"
              id="modal-title"
            >
              {{ "home-page.modal.title" | translate }}
            </h3>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">{{
                "home-page.modal.rating" | translate
              }}</label>
              <div class="gap-2">
                <span
                  class="cursor-pointer m-1 text-[30px] inline-block"
                  *ngFor="let star of rating"
                  (click)="rate(star)"
                  [class]="{
                    'text-turquoise-duckfortiery': star <= this.ratingStars,
                    'text-gray-400': star > this.ratingStars
                  }"
                >
                  &#9733;
                </span>
                <span
                  *ngIf="
                    form.get('rating')?.hasError('required') &&
                    form.get('rating')?.touched
                  "
                  class="text-red-500 block"
                  >{{ "home-page.modal.rating-error" | translate }}</span
                >
              </div>
            </div>

            <div class="mb-4">
              <label
                for="name"
                class="block text-gray-700 text-sm font-bold mb-2"
                >Name:</label
              >
              <input
                id="name"
                type="text"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                formControlName="name"
              />
              <span
                *ngIf="
                  form.get('name')?.hasError('required') &&
                  form.get('name')?.touched
                "
                class="text-red-500"
                >{{ "home-page.modal.name-error" | translate }}</span
              >
            </div>
            <div class="mb-4">
              <label
                for="email"
                class="block text-gray-700 text-sm font-bold mb-2"
                >Email:</label
              >
              <input
                id="email"
                type="email"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                formControlName="email"
              />
              <span
                *ngIf="
                  form.get('email')?.hasError('required') &&
                  form.get('email')?.touched
                "
                class="text-red-500"
                >{{ "home-page.modal.email-error" | translate }}</span
              >
            </div>
            <div class="mb-4">
              <label
                for="description"
                class="block text-gray-700 text-sm font-bold mb-2"
                >{{ "home-page.modal.desc" | translate }}:</label
              >
              <textarea
                id="description"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                formControlName="description"
              ></textarea>
              <span
                *ngIf="
                  form.get('description')?.hasError('description') ||
                  (form.get('description')?.hasError('required') &&
                    form.get('description')?.touched)
                "
                class="text-red-500"
                >{{ "home-page.modal.desc-error" | translate }}</span
              >
              <!-- <span
                *ngIf="form.get('description')?.hasError('minlength')"
                class="text-red-500"
              >
                {{ "home-page.modal.desc-error" | translate }}
              </span> -->
            </div>
            <!-- <div class="mb-4">
              <label
                for="position"
                class="block text-gray-700 text-sm font-bold mb-2"
                >{{ "home-page.modal.position" | translate }}:</label
              >
              <input
                id="position"
                type="text"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                formControlName="position"
              />
              <span
                *ngIf="
                  form.get('position')?.hasError('required') &&
                  form.get('position')?.touched
                "
                class="text-red-500"
                >{{ "home-page.modal.position-error" | translate }}</span
              >
            </div> -->
            <div
              class="w-[200px] flex items-center input-wrapper bg-[#ededed] rounded-sm p-1 h-[42px]"
            >
              <p class="text-lg font-bold flex-shrink-0 ml-4 mr-2">
                {{ firstNum }} + {{ secondNum }} =
              </p>
              <input
                type="text"
                formControlName="userAnswer"
                (keyup)="handleCaptcha()"
                class="w-[45%] bg-white hover:!bg-white !border-white h-[32px]"
              />
            </div>
          </div>
          <div class="bg-gray-50 px-4 gap-2 py-3 sm:px-6 flex flex-row-reverse">
            <button
              [ngClass]="{ 'opacity-50': enableSubmit }"
              [disabled]="enableSubmit"
              type="submit"
              class="bg-turquoise-tertiary text-white font-bold p-[14px] rounded-xl rounded-br-xl"
            >
              {{ "home-page.modal.submit-btn" | translate }}
            </button>
            <button
              type="button"
              class="border-turquoise-tertiary border-2 ml-5 font-bold p-3 text-turquoise-tertiary rounded-xl rounded-br-xl"
              (click)="closeModal()"
            >
              {{ "home-page.modal.cancel-btn" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="container font-sans mx-auto">
      <app-carousel></app-carousel>
    </div>
  </section>
  <section class="py-5 container mx-auto">
    <div
      class="container mx-5 md:mx-auto flex flex-col md:flex-row justify-between md:py-10 py-0"
    >
      <div class="py-5 md:w-[50%] md:mx-10 relative">
        <p
          class="bg-violet-secondary w-fit text-violet-brightpurple rounded-md uppercase px-5 font-bold font-sans mb-5"
        >
          {{ "home-page.services-section.services" | translate }}
        </p>
        <h4 class="text-gray-duckfortiery font-bold">
          {{ "home-page.services-section.offers" | translate }}
        </h4>
        <!-- <div class="absolute right-10 -top-36 md:left-16 md:top-32">
          <img src="../../assets/icons/cleaning-lady-home2.svg" alt="random" />
        </div> -->
      </div>
      <div class="py-5 md:mx-10 md:w-[50%] md:mt-10">
        <p class="md:py-5">
          {{ "home-page.services-section.services-content" | translate }}
        </p>
        <button
          (click)="scrollTo()"
          class="bg-turquoise-tertiary text-white font-bold p-3 rounded-xl rounded-br-xl"
        >
          {{ "home-page.services-section.book-cleaning" | translate }}
        </button>
      </div>
    </div>
    <div
      class="flex mx-5 md:mx-16 md:flex-row flex-wrap flex-col justify-between gap-4 md:pt-12 mt-5"
    >
      @for (services of serviceCards; track services; let index = $index) {
      <div
        [ngClass]="{
          'text-gray-secondary-100 bg-turquoise-duckfortiery': index === 1,
          ' text-gray-ducktertiary bg-green-secondary': index !== 1
        }"
        class="md:w-[49%] rounded-tl-2xl shadow-xl rounded-br-2xl p-5 flex flex-col h-[345px]"
      >
        <p
          [ngClass]="{
            'text-turquoise-duckfortiery bg-green-brightgreen': index === 1,
            ' bg-violet-secondary  text-violet-brightpurple ': index !== 1
          }"
          class="w-fit rounded-md uppercase px-5 font-bold font-sans mb-5"
        >
          {{'home-page.services-section.' + services.service | translate}}
        </p>
        <h5 class="font-bold">{{'home-page.services-section.' + services.title | translate}}</h5>
        <p class="flex-1 overflow-y-auto drop">
          {{'home-page.services-section.' + services.desc | translate}}
        </p>
        <button
          (click)="scrollTo()"
          [ngClass]="{
            'text-turquoise-duckfortiery bg-green-primary': index === 1,
            ' text-white bg-turquoise-tertiary': index !== 1
          }"
          class="font-bold p-3 rounded-xl mt-5 rounded-br-xl w-fit"
        >
          {{ "home-page.services-section.btn" | translate }}
        </button>
      </div>
      }
    </div>
  </section>
  <app-process></app-process>
  <section class="md:py-16">
      <div
        class="container mx-auto flex flex-col md:flex-row justify-between md:py-10"
      >
        <div class="py-5 md:w-[50%] mx-5 md:mx-10">
          <p
            class="bg-violet-secondary w-fit text-violet-brightpurple rounded-md uppercase px-5 font-bold font-sans mb-5"
          >
            {{ "home-page.partner-section.partner" | translate }}
          </p>
          <h4 class="text-gray-duckfortiery font-bold">
            {{ "home-page.partner-section.title" | translate }}
          </h4>
        </div>
        <div class="md:py-5 mx-5 md:mx-10 md:w-[50%] md:mt-10">
          <p class="md:py-5">
            {{ "home-page.partner-section.desc" | translate }}
          </p>
        </div>
      </div>
      <div class="container mx-auto">
        <div class="swiper-container" #swiperContainerr>
          <div class="swiper-wrapper flex items-end">
            <div class="swiper-slide" *ngFor="let card of slides;">
              <div class="slide-content self-end">
                <img [ngClass]="{'!w-[250px]' : card.width}" class="mx-auto w-[150px]" [src]="card.src" alt="random" />
              </div>
            </div>
          </div>
        </div>
        <div class="md:mx-5 flex justify-between">
          <div (click)="slidePrev()">
            <img class="rotate-180 md:pt-[13px] cursor-pointer" src="../../assets/icons/home-chevron-right.svg" alt="Previous" />
          </div>
          <div (click)="slideNext()">
            <img class="pt-[17px] md:pt-4 cursor-pointer" src="../../assets/icons/home-chevron-right.svg" alt="Next" />
          </div>
        </div>
      </div>
  </section>
  <app-facts></app-facts>
  <section class="mx-5 py-16">
        <div class="rounded-tl-xl bg-green-primary rounded-br-xl">
          <div class="container mx-auto items-center flex md:flex-row flex-col">
            <div class="md:w-[50%] p-5 md:p-10">
              <img
                class="p-5"
                src="../../assets/images/recycling.png"
                alt="random"
              />
            </div>
            <div class="self-center md:w-[50%] py-10">
              <div class="py-5 mx-10">
                <p
                  class="bg-violet-secondary w-fit text-violet-brightpurple rounded-md uppercase px-5 font-bold font-sans mb-5"
                >
                  {{ "home-page.last-section.cleaning" | translate }}
                </p>
                <img class="w-[105px]" src="../../assets/images/mrgreenLogo.png" alt="mrGreen">
              </div>
              <div class="mx-10">
                <p class="pb-5">
                  {{ "home-page.currents-section.desc" | translate }}
                </p>
                <div class="flex flex-col md:flex-row gap-2">
                  <a
                    href="https://mr-green.ch/"
                    target="_blank"
                    class="bg-turquoise-tertiary text-white p-3 rounded-xl"
                  >
                  {{ "home-page.last-section.learnmore" | translate }}
                  </a>
                  <a
                    href="mailto: info@mr-green.ch"
                    target="_blank"
                    class="bg-turquoise-tertiary text-white p-3 rounded-xl flex md:justify-between items-center gap-2"
                  >
                    <img src="../../assets/icons/envelope.svg" alt="envelope">
                    {{ "home-page.last-section.contactus" | translate }}
                  </a>
                  <a class="bg-turquoise-tertiary flex items-center justify-center rounded-xl w-10" href="tel:+41 44 271 30 30" target="_blank">
                    <img src="../../assets/icons/phone-footer.svg" alt="phone">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
  </section>
</div>